<template>
  <div class="px-3 py-2">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="d-flex align-items-center">
        <img src="@/assets/images/logo/logo.png" alt="logo" width="80" />
        <span class="title-logo"> Smartworks </span>
      </div>

      <b-link
        to="/hrm"
        class="d-flex align-items-center bg-white p-1 rounded-pill cursor-pointer"
      >
        &#x2715;
        <span class="bold ml-1">Close</span>
      </b-link>
    </div>

    <div class="text-center mt-3 mb-3">
      <h2 style="color: #1e1e1e">Organization Chart</h2>
      <span class="text-muted"> Organization Chart of your Company </span>
    </div>

    <b-card>
      <!-- <organization-chart :datasource="ds" @node-click="detail">
      </organization-chart> -->

      <div class="wrapper">
        <div id="container"></div>
      </div>
    </b-card>

    <span v-b-toggle.sidebar id="openSidebar"></span>

    <b-sidebar id="sidebar" bg-variant="white" shadow backdrop>
      <div class="text-center">
        <b-avatar src="" alt="profile" size="72" />
        <h4 class="mt-2 mb-2 bold">{{ detailData.name }}</h4>
      </div>
      <hr />
      <div class="px-1">
        <div style="line-height: 5px; margin-bottom: 1.5rem">
          <h6 class="bold">Department</h6>
          <span>{{ detailData.title }}</span>
        </div>

        <div style="line-height: 5px; margin-bottom: 1.5rem">
          <h6 class="bold">Manager</h6>
          <span>{{ detailData.parent.name }}</span>
        </div>

        <!-- <div style="margin-bottom: 1.5rem">
          <h6 class="bold">Hierarchy</h6>
          <div
            v-for="(data, index) in detailData.children"
            :key="index"
            :style="'margin-left:' + index + 'rem'"
          >
            <span>{{ data.title }}</span>
          </div>
        </div> -->
      </div>
      <hr />
      <div class="px-1">
        <div style="line-height: 5px; margin-bottom: 1.5rem">
          <h6 class="bold">Name</h6>
          <span>{{ detailData.name }}</span>
        </div>
        <div style="line-height: 5px; margin-bottom: 1.5rem">
          <h6 class="bold">HR Code</h6>
          <span>{{ detailData.name }}</span>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<style scoped>
.wrapper {
  border: 2px dashed rgb(250, 157, 173);
  border-width: 2px;
  width: 100%;
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#container {
  width: 100%;
  overflow: scroll !important;
  float: left;
  height: 500px;
}

.labels {
  float: left;
  width: 200px;
  font-size: 14px;
  position: relative;
  height: 600px;
}
.labels .label {
  position: absolute;
}

#container h4 {
  text-transform: none;
  font-size: 14px;
  font-weight: normal;
}

#container p {
  font-size: 13px;
  line-height: 16px;
}

#sidebar {
  width: 300px !important;
}
</style>

<script>
import OrganizationChart from "vue-organization-chart";
import "vue-organization-chart/dist/orgchart.css";
import Highcharts from "highcharts";
import HighchartsOrganization from "highcharts/modules/organization";
import HighchartsSankey from "highcharts/modules/sankey";
import HighchartsExporting from "highcharts/modules/exporting";

import { BButton, BSidebar, VBToggle } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    OrganizationChart,
    BButton,
    BSidebar,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      ds: {
        id: "1",
        name: "Anung Prabowo",
        title: "Commissioner",
        parent: { id: "", name: "", title: "" },
        children: [
          {
            id: "2",
            name: "Sri Wulandari",
            title: "Director",
            parent: { id: "", name: "", title: "" },
            children: [
              {
                id: "3_1",
                name: "Kania Salma",
                title: "Finance & Management",
                parent: { id: "", name: "", title: "" },
              },
              {
                id: "3_2",
                name: "Hauna Nuha",
                title: "Operation",
                parent: { id: "", name: "", title: "" },
              },
              {
                id: "4",
                name: "Hauna Nuha",
                title: "Operation",
                parent: { id: "", name: "", title: "" },
                children: [
                  {
                    id: "5_1",
                    name: "Hauna Nuha",
                    title: "Operation",
                    parent: { id: "", name: "", title: "" },
                  },
                  {
                    id: "5_2",
                    name: "Hauna Nuha",
                    title: "Operation",
                    parent: { id: "", name: "", title: "" },
                  },
                ],
              },
            ],
          },
        ],
      },

      detailData: {
        id: "",
        name: "",
        title: "",
        parent: { id: "", name: "", title: "" },
        children: [
          {
            id: "",
            name: "",
            title: "",
            parent: { id: "", name: "", title: "" },
          },
        ],
      },

      orgData: [],
      orgNodes: [],

      // items: [{ id: 1, name: "hoge", children: [{ id: 11, name: "hoge-child1" }] }],
    };
  },
  mounted() {
    this.callApi({
      url: "users/organizationChart",
      get: "GET",
      success: (res) => {
        // this.ds = res.result[0];
        // res.result.data.map((data) => {
        //   });
        this.orgData = res.result.data;
        this.orgNodes = res.result.nodes;

        if (typeof Highcharts === "object") {
          HighchartsSankey(Highcharts);
          HighchartsOrganization(Highcharts);
          HighchartsExporting(Highcharts);
        }

        Highcharts.chart("container", {
          chart: {
            height: 600,
            inverted: true,
          },

          title: {
            text: "Organization Chart",
          },

          accessibility: {
            point: {
              descriptionFormatter: function (point) {
                var nodeName = point.toNode.name,
                  nodeId = point.toNode.id,
                  nodeDesc =
                    nodeName === nodeId ? nodeName : nodeName + ", " + nodeId,
                  parentDesc = point.fromNode.id;
                return (
                  point.index +
                  ". " +
                  nodeDesc +
                  ", reports to " +
                  parentDesc +
                  "."
                );
              },
            },
          },

          series: [
            {
              type: "organization",
              name: "HR Academy",
              keys: ["from", "to"],
              // data: [
              //   ["Commissioner", "Director"],
              //   ["Director", "Finance"],
              //   ["Director", "Operation"],
              //   ["Director", "Tech"],
              //   ["Director", "Media"],
              //   ["Director", "Edu"],
              //   ["Director", "Natural"],
              //   ["Tech", "Develop"],
              //   ["Media", "OOKID"],
              //   ["Media", "CW"],
              //   ["Media", "Studio"],
              //   ["Edu", "LKP"],
              //   ["Edu", "LPK"],
              //   ["Edu", "Konsultan"],
              //   ["Edu", "BLK"],
              //   ["Natural", "OOKIDN"],
              //   ["Natural", "Kemitraan"],
              // ],
              data: this.orgData,
              levels: [
                {
                  level: 0,
                  color: "silver",
                  dataLabels: {
                    color: "black",
                  },
                  height: 25,
                },
                {
                  level: 1,
                  color: "#980104",
                },
                {
                  level: 2,
                  color: "#359154",
                },
              ],
              // nodes: [
              //   {
              //     id: "Commissioner",
              //     title: "Commissioner",
              //     name: "Anung Prabowo",
              //   },
              //   {
              //     id: "Director",
              //     title: "Director",
              //     name: "Sri Wulandari",
              //     image:
              //       "https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2018/11/12132317/Grethe.jpg",
              //   },
              //   {
              //     id: "Finance",
              //     title: "Finance",
              //     name: "Kania Salma",
              //     column: 2,
              //     image:
              //       "https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2018/11/12140620/Christer.jpg",
              //     layout: "hanging",
              //   },
              //   {
              //     id: "Operation",
              //     title: "Operation",
              //     name: "Hauna Nuha",
              //     column: 2,
              //     image:
              //       "https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2018/11/12131849/Torstein1.jpg",
              //   },
              //   {
              //     id: "Tech",
              //     title: "Tech",
              //     name: "Hari Agus",
              //     column: 3,
              //     image:
              //       "https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2018/11/12132313/Anita.jpg",
              //     layout: "hanging",
              //   },
              //   {
              //     id: "Media",
              //     title: "Content, Program OOKID & HRA",
              //     name: "Abdul Aziz",
              //     column: 3,
              //     image:
              //       "https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2018/11/12132313/Anita.jpg",
              //     layout: "hanging",
              //   },
              //   {
              //     id: "Edu",
              //     title: "HRA Education",
              //     name: "Hauna Nuha",
              //     column: 3,
              //     image:
              //       "https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2018/11/12132313/Anita.jpg",
              //     layout: "hanging",
              //   },
              //   {
              //     id: "Natural",
              //     title: "HRA Natural",
              //     name: "Weriyan Putri",
              //     column: 3,
              //     image:
              //       "https://wp-assets.highcharts.com/www-highcharts-com/blog/wp-content/uploads/2018/11/12132313/Anita.jpg",
              //     layout: "hanging",
              //   },
              //   {
              //     id: "Develop",
              //     title: "Web Developer",
              //     name: "Rizki Maulana",
              //   },
              //   {
              //     id: "OOKID",
              //     title: "Content, Program HRA & CW",
              //     name: "Julius",
              //   },
              //   {
              //     id: "CW",
              //     title: "CW",
              //     name: "Fadhlan",
              //   },
              //   {
              //     id: "Studio",
              //     title: "HRA Studio",
              //     name: "Kania Salma",
              //   },
              //   {
              //     id: "LKP",
              //     title: "LKP",
              //     name: "Firgi",
              //   },
              //   {
              //     id: "LPK",
              //     title: "LPK",
              //     name: "Firgi",
              //   },
              //   {
              //     id: "Konsultan",
              //     title: "Konsultan",
              //     name: "Hauna Nuha",
              //   },
              //   {
              //     id: "BLK",
              //     title: "BLK",
              //     name: "Faiz Rizky",
              //   },
              //   {
              //     id: "OOKIDN",
              //     title: "OOKID",
              //     name: "Julius",
              //   },
              //   {
              //     id: "Kemitraan",
              //     title: "Kemitraan",
              //     name: "Weriyan Putri",
              //   },
              // ],
              nodes: this.orgNodes,
              colorByPoint: false,
              color: "#007ad0",
              dataLabels: {
                color: "white",
              },
              borderColor: "white",
              nodeWidth: 65,
            },
          ],
          tooltip: {
            outside: true,
          },
          exporting: {
            allowHTML: true,
            sourceWidth: 800,
            sourceHeight: 600,
          },
        });
      },
    });
  },
  methods: {
    detail(node) {
      this.detailData = node;

      const element = document.getElementById("openSidebar");
      element.click();

      console.log(this.detailData);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.title-logo {
  font-size: 1.5rem;
  font-weight: 600;
  color: #423ffd;
  margin-left: 0.5rem;
}

.card .content {
  margin-left: 0px !important;
}
</style>
